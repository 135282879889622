import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { helperTextColor } from '../../constants';
import { Button, Card, CardContent, Container, FormControl, Grid, TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Title } from '../Title';
import { axios } from '../../services/networkRequest';
import { useParams } from 'react-router-dom';
import { translationKey } from '../../utilities/localisation/translationKeys';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: 'auto',
    maxWidth: '50%',
  },
  formControl: {
    margin: "auto",
    width: '100%',
    marginTop: '16px',
    textAlign: 'center',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
  submitButton: {
    float: 'center',
    padding: '0.75rem 3rem',
    background: '#CA218C',
    '&:hover': {
      background: '#9c1169',
    }
  },
  gameLogo: {
    width: '10rem',
    cursor: 'pointer',
  },
  customSupportText: {
    textAlign: 'left',
    marginTop: '50px',
    "@media (min-width: 1025px)": {
      maxWidth: "35vw"
    }
  },
  banner:{
    marginBlockStart:`2rem`,
    background:`rgb(234, 242, 249)`,
    paddingBlockEnd:`2rem`,
    paddingInline:`2rem`,
  },
  errorBox: {
    padding: '1rem',
    marginTop: '2rem',
    border: '3px solid red'
  }
}));
function PostCodeValidationScreen({ entity }) {
  const history = useHistory();
  const classes = useStyles();
  const { keycode } = useParams();
  const {t} = useTranslation();
  const [postcodeRestrictionSettings, setPostcodeRestrictionSettings] = useState(undefined );
  const [customErrorMessage, setCustomErrorMessage] = useState(undefined);
  const [entityId, setEntityId] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [selfSignupEnabled, setSelfSignupEnabled] = useState(false);

  const [showInvalidPostcodeError, setShowInvalidPostcodeError] = useState(false);
  useEffect(() => {
    if(entity) {
      setSelfSignupEnabled(entity.self_sign_up_settings.enable);
      setPostcodeRestrictionSettings(entity.postcode_restriction_settings);
      setCustomErrorMessage(entity.postcode_restriction_settings?.custom_error_message);
      setEntityId(entity.entity_id);
    }
  }, [entity]);

  return (
    <Container component="main" maxWidth="lg">
      {
        selfSignupEnabled ?
      <Card className={classes.card}>
        <CardContent>
          <Grid container justify="center">
            <img src="/ln_logo_transparent_background.png" alt="Lumi Nova logo"
                 className={classes.gameLogo}/>
          </Grid>

          { postcodeRestrictionSettings && postcodeRestrictionSettings.availability_text &&
            <Grid container justify="center">
              <div className={ classes.banner }>
                <Title>{ postcodeRestrictionSettings.availability_text }</Title>
              </div>
            </Grid> }

          <Formik initialValues={{
            postcode: ""
          }}
          onSubmit={(values) =>{
            setShowError(false);
            setShowInvalidPostcodeError(false);
            axios.post(`/entity/${ entityId }/check_postcode`, { ...values })
              .then(({ data }) => {
                setPostcodeRestrictionSettings(data.postcode_restriction_settings)
              })
              .then(response => {
                history.push(`/signup/${keycode}/create_account`, {
                  child_postcode: values.postcode
                })
              })
              .catch((err) => {
                if(err.response.status === 400) {
                  setShowInvalidPostcodeError(true);
                } else {
                  if(!customErrorMessage) {
                    setCustomErrorMessage(err.response.data.error);
                  }
                  setShowError(true);
                }

              })
          }}>
            {({values, handleSubmit, handleBlur, handleChange}) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: '20px', marginTop: '2rem', fontWeight: 'bold' }}>{t(translationKey.LabelCheckYourPostcode)}</Typography>
                      <p>{t(translationKey.LabelEnterYourPostcode)}</p>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          className={classes.textField}
                          label="Postcode"
                          margin="auto"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="text"
                          name="postcode"
                          onBlur={handleBlur}
                          value={values.postcode}
                          FormHelperTextProps={{style: {color: helperTextColor}}}
                          onChange={handleChange}
                          required={true}
                        />
                      </FormControl>
                    </Grid>

                    { customErrorMessage && showError && <Grid container justify="center">
                      <div className={ classes.errorBox }>
                        <Typography align="center">
                          { customErrorMessage }
                        </Typography>
                        { postcodeRestrictionSettings && postcodeRestrictionSettings.custom_error_cta_url && postcodeRestrictionSettings.custom_error_cta_message &&
                          <Typography align="center" style={ { fontWeight: 'bold' } }>
                            <a href={ postcodeRestrictionSettings.custom_error_cta_url } target="_blank"
                               rel="noopener noreferrer">{ postcodeRestrictionSettings.custom_error_cta_message } </a>
                          </Typography> }
                      </div>
                    </Grid> }

                    { showInvalidPostcodeError && <Grid container justify="center">
                      <div className={ classes.errorBox }>
                          <Typography align="center">
                            {t(translationKey.InvalidPostcodeError)}
                          </Typography>
                      </div>
                    </Grid> }

                    <Grid container spacing={2} style={{marginTop: '2rem'}}>
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="primary"
                                className={classes.submitButton}
                                style={{textTransform: 'uppercase'}}>
                          { t(translationKey.ButtonCheckMyEligibility) }
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
          { postcodeRestrictionSettings && <Grid container direction="row" justify="center">
            <Grid item>
              <div className={ classes.customSupportText } dangerouslySetInnerHTML={{ __html: postcodeRestrictionSettings.custom_support_text }} />
            </Grid>
          </Grid> }
        </CardContent>
      </Card>
          :
          <Grid container justify="center">
            <div className={ classes.errorBox }>
              <Typography align="center">
                { t(translationKey.LabelSelfSignupDisabled) }
              </Typography>

            </div>
          </Grid>
      }
    </Container>
  )
}

export default PostCodeValidationScreen;