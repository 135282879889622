import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { useUserContext } from '../../store/UserContext';
import { emailRegex, redirectFactory, ROLES } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { Games } from '../../constants';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100vw',
    height: '100vh',
  },
  logo: {
    position: 'absolute',
    left: 32,
    width: '100px',
    height: 'auto',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

function Login(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { login, pickGame, user } = useUserContext();
  const ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  const url = props.isPointPersonLogin ? '/login/point_person' : '/login';
  const forgotPasswordUrl = props.isPointPersonLogin ? '/point_person/forgot_password' : '/forgot_password';

  useEffect(function () {
    function loadPage(role) {
      if(role) {
        if (role === ROLES.admin) {
          props.history.replace('/');
        } else if (role === ROLES.point_person) {
          props.history.replace('/point_person/dashboard');
        } else {
          props.history.replace('/game_choice');
        }
      }
    }

    if (user) {
      const { role } = user;

      loadPage(role);
    } else {
      axios.get('/get_user')
        .then(({ data }) => {
          login(data);
          loadPage(data.role);
        });
    }
  });

  return (
    <Container className={ classes.background } component="main" maxWidth="xs">
      <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />

      { props.isPointPersonLogin ?
        <Link onClick={ () => { props.history.push('/login') }}>{t(translationKey.TitleDistributorAdminSignIn)}</Link> :
        <Link onClick={ () => { props.history.push('/reports') } }>{t(translationKey.TitleOrganisationAdminSignIn)}</Link>
      }

      <CssBaseline />
      <div className={ classes.paper }>
        { isIE && <div>
          <img src='/redslime.png' alt='red slime' height="100px" />
          <br /><br />
          <Typography component="h1" variant="h4">
            {t(translationKey.ErrorUnsupportedBrowser)}
          </Typography>
          <br />
          <Typography>
            {t(translationKey.ErrorSuggestedBrowsers)}
            <br />
            <Trans
              i18nKey={translationKey.LinkNeedSupport}
              // eslint-disable-next-line
              components={[<a href="mailto:support@bfb-labs.com" />]}
            />
          </Typography>
          <br />
        </div> }

        <Typography component="h1" variant="h5">
          {props.isPointPersonLogin ? t(translationKey.TitleOrganisationAdminSignIn) : t(translationKey.TitleDistributorAdminSignIn)}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ { email: '', password: '' } }
          validate={ values => {
            const errors = {};
            if (values.email && !emailRegex.test(values.email)) {
              errors.email = t(translationKey.ErrorInvalidEmail);
            }
            return errors;
          } }
          onSubmit={ ({ email, password }, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            axios.post(url, { email, password }).then(data => {
              login({ email: email, ...data.data });
              if(props.isPointPersonLogin) {
                props.history.push('/point_person/dashboard');
              } else {
              if (data.data.games) {
                if (data.data.games.length > 1) {
                  props.history.push('/game_choice');
                } else if (data.data.games.length > 0) {
                  const redirect = redirectFactory(props, pickGame);
                  redirect(Games[data.data.games[0]]);
                } else {
                  setFieldError('network', t(translationKey.ErrorNoGameAccess));
                }
              } else {
                props.history.push('/');
              }
            }
          })
              .catch(err => {
                catchAxios(setFieldError)(err);
                setSubmitting(false);
              });
          } }
        >
          { ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                variant="outlined"
                margin="normal"
                required
                type="email"
                fullWidth
                id="email"
                label={t(translationKey.LabelEmailAddress)}
                name="email"
                autoComplete="email"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.email }
                autoFocus
              />
              { errors.email && touched.email && errors.email }
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t(translationKey.LabelPassword)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.password }
              />
              { errors.password && touched.password && errors.password }

              <Link to={forgotPasswordUrl}>{t(translationKey.LinkForgotPassword)}</Link>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={ classes.submit }
                disabled={ isSubmitting }
              >
                {t(translationKey.ButtonSignIn)}
              </Button>
            </form>
          ) }
        </Formik>
      </div>
    </Container>
  );
}

export default Login;
